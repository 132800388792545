<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" centered :title="title">
        <div class="text-center">
            <b-button variant="primary" @click="confirm">
                <font-awesome-icon icon="check"/>
                Confirmer
            </b-button>
            <b-button variant="secondary" @click="hideModal">
                <font-awesome-icon icon="times"/>
                Annuler
            </b-button>
        </div>
    </b-modal>
</template>

<script>
    import modalMixin from '@/mixin/modalMixin';

    export default {
        name: "ConfirmModal",
        mixins: [modalMixin],
        props: {
            title: {
                type: String,
                required: true
            },
            callback: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            commentaire: null,
            modalRef: 'confirmModal'
        }),
        methods: {
            confirm() {
                this.hideModal();
                this.callback();
            }
        }
    }
</script>
